<template>
  <div>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            {{ `Manual` }}
          </v-col>
          <v-col cols="12" sm="6" md="6" class="text-right" v-if="showEmpty">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h6>Message</h6>
                    <vue-editor
                      v-model="selected.message"
                      :editorOptions="vue2EditorOptionsWithEmojis"
                    />
                  </v-col>
                  <!-- <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.message"
                      v-bind:label="`Message`"
                    ></v-textarea>
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="text-center">
                    <v-file-input
                      show-size
                      v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                      @change="onChangePDFFile"
                      v-model="selected.pdfFile.file"
                      accept=".pdf"
                      required
                      :rules="[fileRules]"
                    >
                      <template v-if="selected.pdfFile.pdfFile" #label>
                        {{ selected.pdfFile.pdfFile.file }}
                      </template>
                    </v-file-input>
                    <v-btn
                      v-if="selected.pdfFile.pdfFile"
                      color="blue darken-1"
                      class="mb-2"
                      @click="downloadPDF(selected.pdfFile.pdfFile)"
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      {{ `${$t("Download")} ${$t("PDF File")}` }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="onSaveClick(selected)"
                :loading="isSaving"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="data-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="800"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import { VueEditor, Quill } from "vue2-editor";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji
  },
  true
);

export default {
  name: "Manual",
  components: {
    VueEditor,
  },
  data: function() {
    return {
      status_type: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" }
      ],
      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e7) {
            return true;
          } else {
            return "File size should be less than 10 MB!";
          }
        }
        return true;
      },
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      copyItemId: -1,
      copyBrandIds: [],

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** create or edit dialog options **********/
      showEdit: false,
      dialogCopy: false,
      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],

      isSaving: false,
      isBtnCopying: false,
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,

      totaltableData: 0,
      tableData: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: [
        "brand",
        "description",
        "startDate",
        "endDate",
        "status"
      ],
      filterCriteria: {
        brand: {},
        description: {},
        startDate: {},
        endDate: {},
        status: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      showEmpty: false,

      // postable in dialog
      labelPosArr: [],
      posTableData: [],
      tempdata: [],
      originalposTableData: [],
      options: {},
      filters: { id: [], name: [], status: [] },
      initFilterList: { id: [], name: [], status: [] },
      activeFilters: {},
      searchString: { id: "", name: "", status: "" },

      isSelectingIdPOSperBrand: false,
      selectedFileIdposperBrand: null,
      defaultbuttonTextIdPOSperBrand: "Import"
    };
  },
  watch: {
    dialogCopy(val) {
      val || this.closeCopyDialog();
    },
    async search_brand(val) {
      this.search_brand_list(val);
    },
    options: {
      handler() {
        this.sortPosTableChange();
      },
      deep: true
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    buttonTextIdPOSperLabel() {
      return this.isSelectingIdPOSperBrand
        ? "Uploading..."
        : this.defaultbuttonTextIdPOSperBrand;
    },
    columns() {
      return [
        {
          key: "description",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          width: 200
        },
        {
          key: "message",
          title: "Message",
          field: "message",
          align: "left",
          width: 200,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div
                class="message_contents"
                domPropsInnerHTML={row.message}
              ></div>
            );
          },
        },
        {
          key: "pdfFile",
          title: this.$t("PDF File"),
          field: "pdfFile",
          align: "center",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                {row?.static && (
                  <v-btn
                    color="primary"
                    dark
                    fab
                    on-click={() => this.downloadPDF(row.static)}
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                  </v-btn>
                )}
              </div>
            );
          }
        },
        {
          key: "q",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  medium
                  color="error"
                  on-click={() => this.onItemRemove(row.id)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.posTableData
          .map(d => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        if (col === "id") {
          this.filters[col] = this.initFilterList[col].filter(
            item => item === parseInt(search_str)
          );
        } else {
          this.filters[col] = this.initFilterList[col].filter(item =>
            item.toLowerCase().includes(search_str.toLowerCase())
          );
        }
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.posTableData
        .map(d => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortPosTableChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.posTableData = this.tempdata;
      }
    },
    initTable(newVal) {
      this.posTableData = newVal;
      this.originalposTableData = newVal;
      this.tempdata = newVal;
      this.initFilters();
    },
    initPosTable(data) {
      this.initTable(data);
    },
    onUploadExcelIdPOSperBrand() {
      this.isSelectingIdPOSperBrand = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingIdPOSperBrand = false;
        },
        { once: false }
      );
      this.$refs.uploader_idpos_per_brand.click();
    },
    async onFileChangedIdPOSperBrand(e) {
      this.selectedFileIdposperBrand = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingIdPOSperBrand = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdposperBrand);
        this.$refs.uploader_idpos_per_brand.value = "";
        try {
          const response = await ApiService.post(
            `brands/manual/uploadexcelIdposperbrand/` + this.selected.id,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
            await this.getDataFromApi();
            let selectedIndex = this.tableData.findIndex(
              el => el.id === this.selected.id
            );
            if (selectedIndex >= 0) {
              this.createSelectedModel(this.tableData[selectedIndex]);
            }
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingIdPOSperBrand = false;
        } catch (error) {
          console.log(error);
          this.isSelectingIdPOSperBrand = false;
        }
      }
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#data-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#data-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "brands/manual";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        message: _.get(item, "message"),
        description: _.get(item, "description"),
        pdfFile: {
          file: null,
          pdfFile: _.get(item, "static")
        }
      };
      console.log(model);
      return model;
    },
    onItemEdit(item) {
      console.log(item);
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    async onChangePDFFile(file) {
      this.selected.pdfFile.file = file;
    },
    async search_brand_list(val) {
      let fetch_url = `brands/manual/getfilterlist?column=selectablebrands&isFullText=true&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    async onChangeDates() {
      if (this.selected.startDate > this.selected.endDate) {
        this.selected.endDate = this.selected.startDate;
      }
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        let body = _.omit(item, ["pdfFile"]);
        try {
          this.isSaving = true;
          let status = null;
          let pdfFile = item.pdfFile.file;
          if (pdfFile) {
            let { data } = await ApiService.uploadFile(pdfFile);
            body.pdfFile = data.id;
          } else if (!item.pdfFile.pdfFile) {
            logError("Se requiere un archivo PDF.");
            this.isSaving = false;
            return;
          }
          console.log(body);
          if (body.id) {
            status = await ApiService.put(`brands/manual/${body.id}`, body);
          } else {
            status = await ApiService.post(`brands/manual`, body);
          }
          logInfo("éxito");
          this.showEdit = false;
          this.isSaving = false;
          this.getDataFromApi();
        } catch (error) {
          this.showEdit = false;
          this.isSaving = false;
        }
      }
    },
    onItemRemove(item_id) {
      console.log(item_id);
      this.deleteItemId = item_id;
      if (this.deleteItemId > 0 && window.confirm(this.$t("confirmdelete"))) {
        ApiService.delete(`brands/manual/${this.deleteItemId}`).then(() => {
          logInfo("eliminada!");
          this.getDataFromApi();
        });
      }
    },
    async downloadPDF(document) {
      this.show();
      let file_uri = ApiService.getFileUri(document ? document : null);
      console.log(file_uri)
      if (file_uri !== null) {
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },
    async onItemCopy(id) {
      this.copyItemId = id;
      this.copyBrandIds = [];
      this.dialogCopy = true;
    },
    closeCopyDialog() {
      this.dialogCopy = false;
      this.$nextTick(() => {
        this.copyItemId = -1;
      });
    },
    async copyItemConfirm() {
      if (this.copyItemId > 0 && this.copyBrandIds.length > 0) {
        this.isBtnCopying = true;
        try {
          let status = await ApiService.post(
            `brands/manual/copydata/${this.copyItemId}`,
            {
              brandIds: this.copyBrandIds
            }
          );
          this.isBtnCopying = false;
          this.closeCopyDialog();
          if (status) {
            const { success, message, data } = status;
            if (success) {
              logInfo("éxito");
              this.getDataFromApi();
            } else if (success === false) {
              logError(message);
            }
          }
        } catch (error) {
          this.closeCopyDialog();
          this.isBtnCopying = false;
        }
      } else {
        logError(this.$t("You must select one more brand."));
      }
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async downloadexcelposes() {
      let fetch_url = `brands/manual/downloadexcelposes/${this.selected.id}`;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "data.xlsx");
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
